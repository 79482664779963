import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

// icons
import { HiX } from "react-icons/hi";
import { RxHamburgerMenu } from "react-icons/rx";

//components
import SideItem from "./SideItem";
import { Services, Experts, Blogs } from "../navbar/NavContent";

// styles & images
import "./SideBar.css";
import CounselingFitLogo from "../../assets/logo/counseling-fit-logo.png";

export default function SideBar() {
  const [burgerToggle, setBurgerToggle] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setBurgerToggle(false);
  }, [location.pathname]);

  const loadHomepage = () => {
    navigate("/");
  };

  return (
    <div
      className="sidebar flex align-center"
      style={
        burgerToggle
          ? {
              position: "sticky",
              zIndex: "10",
            }
          : {}
      }
    >
      <nav className="sidebar-content flex align-center justify-between">
        <div className="sidebar-logo flex" onClick={loadHomepage}>
          <img src={CounselingFitLogo} alt="counseling fit" />
        </div>
        <div
          className={`sidebar-links ${burgerToggle ? "" : "hide-burger-menu"}`}
        >
          <HiX
            className="burger-close"
            onClick={() => {
              setBurgerToggle(false);
            }}
          />
          <ul className="sidebar-items flex">
            {burgerToggle && (
              <>
                <li>
                  <SideItem dropdown={true} dropdownContent={<Services />}>
                    Services
                  </SideItem>
                </li>
                <li>
                  <SideItem dropdown={true} dropdownContent={<Experts />}>
                    Talk to Experts
                  </SideItem>
                </li>
                <li>
                  <SideItem styles={""}>Now&Me Experts</SideItem>
                </li>
                <li>
                  <SideItem>Community</SideItem>
                </li>
                <li>
                  <SideItem dropdown={true} dropdownContent={<Blogs />}>
                    Blogs & Resources
                  </SideItem>
                </li>
                <li>
                  <SideItem styles={""}>For Corporates</SideItem>
                </li>
              </>
            )}
          </ul>
        </div>
        <RxHamburgerMenu
          className="sidebar-burger"
          onClick={() => {
            setBurgerToggle(true);
          }}
        />
      </nav>
    </div>
  );
}
