import { NavLink, useNavigate } from "react-router-dom";

// icons
import { FaMagic } from "react-icons/fa";
import { RxCaretDown } from "react-icons/rx";

//components
import NavItem from "./NavItem";
import { Services, Experts, Blogs } from "./NavContent";

// styles & images 
import "./NavBar.css";
import CounselingFitLogo from "../../assets/logo/counseling-fit-logo.png";

export default function NavBar() {
    const navigate = useNavigate();
    
    const loadHomepage = () => {
        navigate("/");
    }

    return (
        <div className="navbar flex align-center">
            <nav className="navbar-content flex align-center justify-between">

                <div className="navbar-logo flex-center" onClick={loadHomepage}>
                    <img src={CounselingFitLogo} alt="counseling fit" />
                </div>

                <div className="navbar-links">
                    <ul className="navbar-items flex justify-around align-center">
                        <li>
                            <NavItem dropdown={true} dropdownContent={<Services/>}>
                                Services
                                <RxCaretDown className="btn-caret" />
                            </NavItem>
                        </li>
                        <li>
                            <NavItem dropdown={true} dropdownContent={<Experts/>}>
                                Talk to Experts
                                <RxCaretDown className="btn-caret" />
                            </NavItem>
                        </li>    
                        <li>
                            <NavItem path={'/workshop'}>
                                Workshops
                            </NavItem>
                        </li>
                        <li>
                            <NavItem >
                                Reviews
                            </NavItem>
                        </li>
                        <li>
                            <NavItem >
                                About
                            </NavItem>
                        </li>
                        <li>
                            <NavItem >
                                FAQ
                            </NavItem>
                        </li>
                        <li> 
                            <NavItem dropdown={true} dropdownContent={<Blogs />}>
                                Blogs & Resources
                                <RxCaretDown className="btn-caret" />
                            </NavItem>
                        </li>
                        <li>
                            <NavItem >
                                Contact
                            </NavItem>
                        </li>   
                        <li>
                            <NavLink className="btn btn-filled flex-center margin-l16" to="/">
                                Ai Assistant
                                <FaMagic className="btn-logo margin-l8"/>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink className="btn btn-empty flex" to="signup">
                                signup
                            </NavLink>
                        </li>
                    </ul>   
                </div>

            </nav>
        </div>
    );
}