import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { RxCaretDown } from "react-icons/rx";

// styles & images
import "./SideContent.css";

export default function SideItem({
  children,
  styles,
  dropdown,
  dropdownContent,
}) {
  const [dropdownToggle, setDropdownToggle] = useState(false);

  return (
    <>
      {!dropdown && (
        <NavLink
          className={`navlink-header flex align-center ${styles}`}
          to="/"
        >
          {children}
        </NavLink>
      )}

      {dropdown && (
        <>
          <NavLink
            className={`navlink-header flex align-center ${styles}`}
            to="/"
            onClick={() => {
              setDropdownToggle(!dropdownToggle);
            }}
          >
            {children}
            <RxCaretDown
              className={dropdownToggle ? "btn-caret-left" : "btn-caret-right"}
            />
          </NavLink>
          <div
            className={`navbar-dropdown ${dropdownToggle ? "show" : "hidden"}`}
          >
            <NavLink
              className={`navlink-header flex align-center margin-t12 ${styles}`}
              to="/"
              onClick={() => {
                setDropdownToggle(!dropdownToggle);
              }}
            >
              <RxCaretDown
                className={
                  dropdownToggle ? "btn-caret-left" : "btn-caret-right"
                }
              />
              {children}
            </NavLink>
            {dropdownToggle && (
              <div>
                <div className="border navbar-dropdown-space"></div>
                {dropdownContent}
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}
