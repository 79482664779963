// styles & images
import "./Spinner.css";

export default function Spinner({ size, border, loaderWidth }) {
  return (
    <div
      className="loader flex-center"
      style={{
        width: "100%",
        height: "calc(100% - 100px)",
      }}
    >
      <p
        className="spinner"
        style={{
          height: `${size}`,
          width: `${size}`,
          borderWidth: `${border}`,
        }}
      ></p>
    </div>
  );
}
