import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// styles & images
import "./App.css";

// pages & components
import Navigation from "./components/Navigation";
import Spinner from "./components/other/Spinner";
const Home = lazy(() => import("./pages/home/Home"));
const Therapists = lazy(() => import("./pages/therapists/Therapists"));
const Profiles = lazy(() => import("./pages/profiles/Profiles"));
const SignUp = lazy(() => import("./pages/signup/SignUp"));
const Login = lazy(() => import("./pages/login/Login"));

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navigation />
        <Suspense fallback={<Spinner size={"80px"} border={"8px"} />}>
          <Routes>
            <Route index element={<Home />} />
            <Route path="online-therapists" element={<Therapists />} />
            <Route
              path="online-therapists/:profiles/:name"
              element={<Profiles />}
            />
            <Route path="signup" element={<SignUp />} />
            <Route path="login" element={<Login />} />
            <Route path="*" element={<Navigate to="login" />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
