import React from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";

export default function NavItem({children, styles, dropdown, dropdownContent, path}) {
    const [dropdownToggle, setDropdownToggle] = useState(false);

    return (
        <>
            {!dropdown && 
            <div className="navbar-dropdown">
                <NavLink className={`flex align-center ${styles}`} to={path}>
                    {children}
                </NavLink>
            </div>

            }

            {dropdown && 
            <div 
                className="navbar-dropdown" 
                onMouseEnter={() => {setDropdownToggle(true)}} 
                onMouseLeave={() => {setDropdownToggle(false)}} 
            >
                <NavLink className={`flex align-center ${styles}`} to={path}>
                    {children}
                </NavLink>
                {dropdownToggle &&
                    <div>
                        <div className="navbar-dropdown-space"></div>
                        {dropdownContent}
                    </div>
                }
            </div>
            }

        </>

    )
};