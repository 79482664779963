// compnents 
import NavBar from './navbar/NavBar';
import SideBar from './sidebar/SideBar';

export default function Navigation() {
    return (
        <>
            <NavBar />
            <SideBar />
        </>
    );
}