import React from "react";
import { RxCaretRight } from "react-icons/rx";
import { NavLink } from "react-router-dom";

// styles & images 
import './NavContent.css';

const ServicesColumn = [
    ["Get Help For :", "Therapy for Mental Wellness :", "Coaching for self-improvement :"],
    [
        ["Anxiety"], 
        ["Stress"],
        ["Saddness"], 
        ["Confusion"], 
        ["Feeling Lost"], 
        ["Feeling Alone"],
    ],
    [
        ["Trauma"],
        ["Burnout"], 
        ["Socialety Pressure"], 
        ["Inferior Complex"], 
        ["Exam Anxiey"], 
        ["Social Anxiety"],
    ],
    [
        ["Panic Attacks"],
        ["Peer Pressure"],
        ["Toxic Workplace"],
        ["Imposter Syndrome"],
        ["Lack of Confidence"],
        ["Lack of Esteem"],
    ],
    [
        ["Marriage Counseling"],
        ["Relationship Counseling"],
        ["Depression Counseling"],
        ["LGBTQ Therapy"],
        ["Individual Therapy"],
        ["Anxiety Therapy"],
        ["Online Therapy"],        
    ],
    [
        ["Life Coaching"],
        ["Mindful Coaching"],
        ["Positivity Coaching"],
        ["Career Coaching"],
        ["Sexual Wellness Coaching"],
        ["Mind Transformation"],
    ],
];

const Services = () => {
    return (
        <div className="flex dropdown-menu green-shadow dropdown-services" >

            <div className="flex services-column">
                <p className="dropdown-heading">{ServicesColumn[0][0]}</p>
                <div className="flex services-content">
                    <div className="flex">
                        {ServicesColumn[1].map((col1, index) => (
                            <React.Fragment key={index}>
                                <NavLink to="/">{col1[0]} <RxCaretRight /></NavLink>
                            </React.Fragment>
                        ))} 
                    </div>
                    <div className="flex">
                        {ServicesColumn[2].map((col2, index) => (
                            <React.Fragment key={index}>
                                <NavLink to="/">{col2[0]} <RxCaretRight /></NavLink>
                            </React.Fragment>
                        ))} 
                    </div>
                    <div className="flex">
                        {ServicesColumn[3].map((col3, index) => (
                            <React.Fragment key={index}>
                                <NavLink to="/">{col3[0]} <RxCaretRight /></NavLink>
                            </React.Fragment>
                        ))} 
                    </div>
                </div>
            </div>

            <div className="flex services-column">
                <p className="dropdown-heading">{ServicesColumn[0][1]}</p>
                {ServicesColumn[4].map((col4, index) => (
                    <React.Fragment key={index}>
                        <NavLink to="/">{col4[0]} <RxCaretRight /></NavLink>
                    </React.Fragment>
                ))} 
            </div>
            <div className="flex services-column">
                <p className="dropdown-heading">{ServicesColumn[0][2]}</p>
                {ServicesColumn[5].map((col5, index) => (
                    <React.Fragment key={index}>
                        <NavLink to="/">{col5[0]} <RxCaretRight /></NavLink>
                    </React.Fragment>
                ))} 
            </div>
        </div>
    )
}

const ExpertsColumn = [
    ["Therapists", "online-therapists"],
    ["Relationship Coach"],
    ["Counseling Psychologist"],
    ["Clinical Psychologist"],
    ["Life Coach"],
    ["Mindfulness Coach"],
    ["Positivity Coach"],
    ["Career Coach"],
    ["Sexual Welness Coach"],
    ["Mind Transformation Coach"],
];

const Experts = () => {
    return (
        <div className="flex dropdown-menu green-shadow dropdown-experts" >
            <p className="flex align-center margin-l8 dropdown-heading">Connection with: </p>
            {ExpertsColumn.map((experts, index) => (
                <React.Fragment key={index}>
                    <NavLink to={`/${experts[1]}`}>{experts[0]} <RxCaretRight /></NavLink>
                </React.Fragment>
            ))}
        </div>
    )
}

const BlogsColumn = [
    ["Blogs"],
    ["Short Reads"],
    ["Guides"],
    ["Shop"],
];

const Blogs = () => {
    return (
        <div className="flex dropdown-menu green-shadow dropdown-blogs" >
            {BlogsColumn.map((blogs, index) => (
            <React.Fragment key={index}>
                <NavLink to="/">{blogs[0]} <RxCaretRight /></NavLink>
            </React.Fragment>
            ))}
        </div>
    );
}

export {Services, Experts, Blogs};